import { AssetStatus, UserRole } from "@/model/Enums";
import { AssetFile, IAssetFile } from "@/model/AssetFile";
import { IDocument, Document } from "@/model/Document";
import { mapData } from "@/utilities/DataMapping";
import utils from "@/utilities/Utils";

export interface IAsset {
    id: number;
    guid: string;
    name: string;
    description: string;
    clientID: number;
    regionIDs: Array<number>;
    categoryIDs: Array<number>;
    logoKey: string;
    assetsLastModified: Date;
    restricted: boolean;
    restrictedToRoles: Array<UserRole>;
    status: AssetStatus;
    lastUpdated: Date;
    fileCount: number;
    folderSize: number;
    markedForDeletion: Date;
    markedForDeletionByUserID: number;

    logo: IDocument;
    files: Array<IAssetFile>;
}

export class Asset implements IAsset {

    constructor(data?: IAsset) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: IAsset) {
        mapData(data, { 
            root: () => this,
            logo: () => new Document(),
            files: () => new AssetFile()
        });
    }

    id: number = 0;
    guid: string = utils.emptyGuidValue;
    name: string = "";
    description: string = "";
    clientID: number = 0;
    regionIDs: Array<number> = [];
    categoryIDs: Array<number> = [];
    logoKey: string = utils.emptyGuidValue;
    assetsLastModified: Date = new Date(utils.emptyDateValue);
    restricted: boolean = false;
    restrictedToRoles: Array<UserRole> = [];
    status: AssetStatus = 0;
    lastUpdated: Date = new Date(utils.emptyDateValue);
    markedForDeletion: Date = new Date(utils.emptyDateValue);
    markedForDeletionByUserID: number = 0;
    fileCount: number = 0;
    folderSize: number = 0;

    logo: Document = new Document();
    files: Array<AssetFile> = [];

    logoSrc: string = "";

}