import { AssetStatus } from "@/model/Enums";

export class AssetSearchParameters {

    pageNumber: number = 1;
    searchText: string = "";
    clientID: number = 0;
    regionIDs: Array<number> = [];
    categoryIDs: Array<number> = [];
    states: Array<AssetStatus> = [];
    includeArchived: boolean = false;

}