import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface IDocument {
    key: string;
    filename: string;
    extension: string;
    contentType: string;
    size: number;

    imgSrc: string;
}

export class Document implements IDocument {

    constructor(data?: IDocument) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: IDocument) {
        mapData(data, { root: () => this });
    }

    key: string = utils.emptyGuidValue;
    filename: string = "";
    extension: string = "";
    contentType: string = "";
    size: number = 0;

    imgSrc: string = "";
    
}