

    // Stuff with $listeners:
    // https://vuejs.org/v2/guide/components-custom-events.html

    import Vue from "vue";
    import Component from "vue-class-component";
    import { Prop } from "vue-property-decorator";
    import utils from "@/utilities/Utils";
    import eventBus from "@/utilities/EventBus";

    @Component({})
    export default class ApiButton extends Vue {

        mounted() {
            eventBus.$on("enableApiButton", this.enable);
            eventBus.$on("disableApiButton", this.disable);
        }

        private id: string = utils.newGuid();

        @Prop({ default: "red darken-2" }) private color!: string;
        @Prop({ default: "white" }) private textColor!: string;
        @Prop({ default: false }) private disabled?: boolean;
        @Prop({ default: false }) private outlined!: boolean;
        @Prop({ default: false }) private large!: boolean;
        @Prop({ default: false }) private small!: boolean;
        @Prop({ default: false }) private block!: boolean;
        @Prop({ default: false }) private icon?: boolean;
        @Prop({ default: false }) private dark?: boolean;
        @Prop({ default: false }) private text?: boolean;
        @Prop({ default: false }) private fab!: boolean;
        @Prop({ default: null }) private to?: string;
        
        loading: boolean = false;

        get listeners(): any {
            // we could add in our own custom listeneres here
            return { ...this.$listeners };
        }

        enable(id: string) {
            if (id == this.id) this.loading = false;
        }

        disable(id: string) {
            if (id == this.id) this.loading = true;
        }

    }
