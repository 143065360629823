import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface ILookupItem {
    id: number|string; // number or string (GUIDs)???
    description: string;
    displayOrder: number;
    isArchived: boolean;
    assets: number;
}

export class LookupItem implements ILookupItem {

    constructor(data?: ILookupItem) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: ILookupItem) {
        mapData(data, { root: () => this });
    }

    id = 0; 
    description = "";
    displayOrder = 0;
    isArchived = false;
    assets = 0;

    get isNew() {
        return utils.isEmptyId(this.id);
    }
    
}