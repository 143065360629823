
    import Vue from "vue";
    import Component from "vue-class-component";
    import authentication from "@/utilities/Authentication";
    import ApiButton from "../components/ApiButton.vue";
    import store from "@/store/store";
    import { UserRole } from "@/model/Enums";
    import eventBus from "@/utilities/EventBus";
    import apiClient from "@/utilities/ApiClient";
    import { IAsset, Asset } from "@/model/Asset";

    @Component({
        components: { ApiButton }
    })  
    export default class SignedInLayout extends Vue {

        mounted() {
            document.body.style.backgroundColor = "#fff";
            eventBus.$on("favoritesUpdated", this.getFavorites);
            this.getFavorites();
        }

        // properties 

        mini = true;
        favorites: Array<Asset> = [];

        // computed properties

        private get loggedInUserName() {
            return `${store.state.signedInUser?.forename} ${store.state.signedInUser?.surname}`;
        }

        private get loggedInUserInitials() {
            return `${store.state.signedInUser?.forename.substring(0,1)}${store.state.signedInUser?.surname.substring(0,1)}`;
        }

        private get isSuperAdmin() {
            return store.state.signedInUser?.role == UserRole.SuperAdmin;
        }

        private get isAdmin() {
            return store.state.signedInUser?.role == UserRole.SuperAdmin || store.state.signedInUser?.role == UserRole.Admin;
        }

        // methods

        private signOut() {
            // Not using async / await here so we'll just trust the sign out occurs in the background
            // and rely on error handler if anything screws up.
            authentication.signOut();
            this.$router.push("/");
        }

        private async getFavorites() {
            this.favorites.length = 0;
            const response: Array<IAsset> = await apiClient.get("/api/asset/favorites");
            this.favorites.push(...response.map(f => new Asset(f)));
        }

    }
