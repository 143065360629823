import utils from "@/utilities/Utils";
import { UserRole, UserStatus } from "./Enums";
import { mapData } from "@/utilities/DataMapping";

export interface IUser {
    id: number;
    guid: string;
    role: UserRole;
    status: UserStatus;
    forename: string;
    surname: string;
    email: string;
    lastLoggedIn: Date;
    lastLoggedInTicks: number;
    activationEmailSent: Date;
    activationEmailSentByUserID: number;
    suppressTriggerEmails: boolean;
    locationID: number;
    departmentID: number;
}

export class User implements IUser {

    constructor(data?: IUser) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: IUser) {
        mapData(data, { root: () => this });
    }

    id: number = 0;
    guid: string = utils.emptyGuidValue;
    role: UserRole = 0;
    status: UserStatus = 0;
    forename: string = "";
    surname: string = "";
    email: string = "";
    lastLoggedIn: Date = new Date(utils.emptyDateValue);
    lastLoggedInTicks: number = 0;
    passwordLastChanged: Date = new Date(utils.emptyDateValue);
    activationEmailSent: Date = new Date(utils.emptyDateValue);
    activationEmailSentByUserID: number = 0;
    suppressTriggerEmails: boolean = false;
    locationID: number = 0;
    departmentID: number = 0;
    jwtBearerToken: string = "";

    get isNew() {
        return utils.isEmptyId(this.id);
    }

    get isDeleted() {
        return this.status = UserStatus.Deleted;
    }

    get fullname() {
        return this.forename + " " + this.surname;
    }
    
}