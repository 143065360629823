import { AssetFileStatus, UserRole } from "@/model/Enums";
import { IDocument, Document } from "@/model/Document";
import { mapData } from "@/utilities/DataMapping";
import utils from "@/utilities/Utils";

export interface IAssetFile {
    id: number;
    assetID: number;
    fileKey: string;
    status: AssetFileStatus;
    pinned: boolean;
    previewDocumentKey: string;
    restricted: boolean;
    restrictedToRoles: Array<UserRole>;
    markedForDeletion: Date;
    markedForDeletionByUserID: number;
    archived: Date;
    archivedByUserID: number;
    downloadCount: number;

    document: IDocument;
    previewImage: IDocument;
}

export class AssetFile implements IAssetFile {

    constructor(data?: IAssetFile) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: IAssetFile) {
        mapData(data, { 
            root: () => this,
            document: () => new Document(),
            previewImage: () => new Document()
        });
    }
    
    id: number = 0;
    assetID: number = 0;
    fileKey: string = "";
    status: AssetFileStatus = 0;
    pinned: boolean = false;
    previewDocumentKey: string = "";
    restricted: boolean = false;
    restrictedToRoles: Array<UserRole> = [];
    markedForDeletion: Date = new Date(utils.emptyDateValue);
    markedForDeletionByUserID: number = 0;
    archived: Date = new Date(utils.emptyDateValue);
    archivedByUserID: number = 0;
    downloadCount: number = 0;
    
    document: Document = new Document();
    previewImage: Document = new Document();

    get icon() {
        if (this.document.contentType.indexOf("image/") > -1) return "mdi-file-image";
        else if (this.document.contentType.indexOf("audio/") > -1) return "mdi-file-music";
        else if (this.document.contentType.indexOf("video/") > -1) return "mdi-file-video";

        switch (this.document.extension) {
            case ".txt": return "mdi-file-document";
            case ".pdf": return "mdi-file-pdf";
            case ".xla": 
            case ".xlam": 
            case ".xll": 
            case ".xlm": 
            case ".xls": 
            case ".xlsm": 
            case ".xlsx": 
            case ".xlt": 
            case ".xltm": 
            case ".xltx": return "mdi-file-excel";
            case ".doc":
            case ".docm":
            case ".dot":
            case ".dotx":
            case ".docx": return "mdi-file-word";
            case ".xml":
            case ".html":
            case ".css":
            case ".js": return "mdi-file-code"
            default: return "mdi-file";
        }
    }

    get pinnedIcon() {
        return this.pinned ? "mdi-pin" : "mdi-pin-outline"
    }

}