import Vue from "vue";
import utils from "@/utilities/Utils";
import { LookupItem } from "@/model/LookupItem";

//
// -- booleans
//

Vue.filter("yesNoText", (value: boolean): string => {
    return value ? "Yes" : "No";
});

//
// -- dates
//

Vue.filter("dateText", (value: Date): string => {
    if (!value) return "- - -";
    return utils.dateText(value);
});

Vue.filter("dateTimeText", (value: Date): string => {
    if (!value) return "- - -";
    return utils.dateTimeText(value);
});

Vue.filter("whenText", (value: Date): string => {
    if (!value) return "- - -";
    return utils.whenText(value);
});

//
// -- numbers
//

Vue.filter("currencyAmount", (value: number): string => {
    return !isNaN(value) && value.toFixed(2) || "0.00";
});

Vue.filter("fileSize", (bytes: number): string => {
    return utils.fileSize(bytes);
});

//
// -- lookups
//

Vue.filter("lookup", (id: number | string, lookupList: Array<LookupItem>, defaultText: string = "", failedText: string = ""): string => {
    if (!failedText) failedText = `(id=${id}) opts=${(lookupList ? lookupList.length : (typeof lookupList))}`;
    if (id === 0 || id === "" || id === utils.emptyGuidValue) return defaultText;
    if (!Array.isArray(lookupList)) return "(options not an array)";
    if (lookupList.length === 0) return "(empty options array)";
    const item = lookupList.filter(l => l.id === id)[0];
    return item ? item.description : failedText;
});