import eventBus from "./EventBus";

module ButtonToggler {

    export function getButton(eventOrButton: any): HTMLInputElement | undefined  {
        if (!eventOrButton) return undefined;

        if (!!eventOrButton &&
            !!eventOrButton.tagName &&
            eventOrButton.tagName.toLowerCase() !== "button") return eventOrButton as HTMLInputElement;

        // event.srcElement is deprecated
        // event.currentTarget is whatever the event listener is attached to
        // event.target is the actual thing that the user clicked on

        if (!!eventOrButton.target &&
            !!eventOrButton.target.tagName &&
            eventOrButton.target.tagName.toLowerCase() === "button") return eventOrButton.target as HTMLInputElement;

        if (!!eventOrButton.srcElement &&
            !!eventOrButton.srcElement.tagName &&
            eventOrButton.srcElement.tagName.toLowerCase() === "button") return eventOrButton.srcElement as HTMLInputElement;

        if (!!eventOrButton.parentElement &&
            !!eventOrButton.parentElement.tagName &&
            eventOrButton.parentElement.tagName.toLowerCase() === "button") return eventOrButton.parentElement as HTMLInputElement;

        if (!!eventOrButton.currentTarget &&
            !!eventOrButton.currentTarget.tagName &&
            eventOrButton.currentTarget.tagName.toLowerCase() === "button") return eventOrButton.currentTarget as HTMLInputElement;

        if (!!eventOrButton.srcElement &&
            !!eventOrButton.srcElement.parentElement &&
            !!eventOrButton.srcElement.parentElement.tagName &&
            eventOrButton.srcElement.parentElement.tagName.toLowerCase() === "button") return eventOrButton.srcElement.parentElement as HTMLInputElement;

        return undefined;
    }

    export function disableButton(button?: any) {
        if (!button) return;
        button.disabled = true;
        eventBus.$emit("disableApiButton", button.id);
    }

    export function enableButton(button?: any) {
        if (!button) return;
        button.disabled = false;
        eventBus.$emit("enableApiButton", button.id);
    }
} 

export default ButtonToggler;