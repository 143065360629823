
    import Vue from "vue";
    import Component from "vue-class-component";
    import SlideUpDown from "vue-slide-up-down";
    import apiClient from "@/utilities/ApiClient";
    import authentication from "@/utilities/Authentication";
    import ApiButton from "../components/ApiButton.vue";
    import { Ref} from "vue-property-decorator";
    import { VForm } from "@/types/types";
    import { User, IUser } from "../model/User";
    import { LookupGroup, SignInView } from "@/model/Enums";
    import utils from "@/utilities/Utils";
import eventBus from "@/utilities/EventBus";
import { ILookupItem, LookupItem } from "@/model/LookupItem";

    // We could put these interfaces (or classes) in their own files - but they're small 
    // and so far they're only used here.

    interface ISignInRequest {
        email: string;
        password: string;
    }

    export interface ISignInResponse {
        isSuccess: boolean;
        message: string;
        user: IUser;
    }

    interface IForgotRequest {
        email: string;
    }

    interface IForgotResponse {
        isSuccess: boolean;
        message: string;
    }

    interface IRegisterRequest {
        forename: string;
        surname: string;
        email: string;
        locationID: number;
        departmentID: number;
    }

    interface IRegisterResponse {
        isSuccess: boolean;
        message: string;
    }

    // https://vuejs.org/v2/guide/typescript.html

    @Component({
        components: { SlideUpDown, ApiButton }
    })     
    export default class SignInForm extends Vue {

        // Lifecycle hook
        mounted() {
            this.loadFromStorage();

            Promise.all([
                this.getLocations(),
                this.getDepartments()
            ]);            
        }

        // forms

        @Ref("signInForm") private readonly signInForm!: VForm;
        @Ref("forgotPasswordForm") private readonly forgotPasswordForm!: VForm;
        @Ref("registerForm") private readonly registerForm!: VForm;

        // properties

        private signInRequest: ISignInRequest = { email: "", password: "" };
        private forgotRequest: IForgotRequest = { email: "" };
        registerRequest: IRegisterRequest = { forename: "", surname: "", email: "", locationID: 0, departmentID: 0 };

        private mode: SignInView = SignInView.SignIn

        private registrationMessageType: string = "error";
        private messageText = "";

        // computed properties

        private get isMessage(): boolean {
            return !!this.messageText;
        }

        private get isAuthenticated(): boolean {
            return this.$store.state.signedInUser !== null;
        }

        private get userName(): string {
            const user = this.$store.state.signedInUser as User;
            if (user === null) return "not logged in";
            return `${user.forename} ${user.surname}`;
        }

        // lookups

        private locations: Array<LookupItem> = [];
        private get locationOptions(): Array<LookupItem> {
            return this.locations.filter(l => !l.isArchived);
        }

        private departments: Array<LookupItem> = [];
        private get departmentOptions(): Array<LookupItem> {
            return this.departments.filter(d => !d.isArchived);
        }

        // methods

        private goToView(view: SignInView) {
            this.signInForm.resetValidation();
            this.forgotPasswordForm.resetValidation();
            this.registerForm.resetValidation();

            this.messageText = "";

            if (this.mode == SignInView.SignIn && view == SignInView.FogottenPassword) {
                // copy the email over to save user typing it out again
                utils.resetObject(this.forgotRequest);
                this.forgotRequest.email = this.signInRequest.email;
            }

            if (this.mode == SignInView.SignIn && view == SignInView.Register) {
                // copy the email over to save user typing it out again
                utils.resetObject(this.registerRequest);
                this.registerRequest.email = this.signInRequest.email;
            }

            this.mode = view;
        }

        private async signIn(event?: Event) {
            const isValid = this.signInForm.validate();

            if (!isValid) {
                this.messageText = "Please enter your email address and password!";
                return;
            }

            this.messageText = "";

            const response: ISignInResponse = await apiClient.post("/Api/Authentication/signin", this.signInRequest, event);

            await authentication.signIn(new User(response.user));

            if (this.isAuthenticated) {
                this.saveToStorage(response.user);
                console.log("...SignInForm component - emitting is-authenticated");
                this.$emit("is-authenticated");
            }
            else {
                this.messageText = response.message;
            }
        }

        private async submitForgot(event?: Event) {
            if (!this.forgotPasswordForm.validate()) return;

            const response: IForgotResponse = await apiClient.post("/Api/Authentication/forgot", this.forgotRequest, event);
            this.messageText = response.message;
        }

        private loadFromStorage() {
            if (typeof (Storage) === "undefined") return;
            const userJson = localStorage.getItem("user");
            if (!userJson) return;
            try {
                const user = JSON.parse(userJson);
                this.signInRequest.email = (user && user.email) ? user.email : "";
                this.forgotRequest.email = this.signInRequest.email;
            } catch (e) {
                this.signInRequest.email = "";
            }
        }

        private saveToStorage(user: IUser) {
            if (typeof (Storage) === "undefined") return;
            localStorage.setItem("user", JSON.stringify(user));
        }

        private async submitRegistration(event?: Event) {
            if (!this.registerForm.validate()) return;

            const response: IRegisterResponse = await apiClient.post("/api/authentication/register", this.registerRequest, event);
            this.messageText = response.message;
            this.registrationMessageType = response.isSuccess ? "success" : "error";
        }

        // lookups

        private async getLocations() {
            const response: Array<ILookupItem> = await apiClient.get(`api/lookup/${LookupGroup.Locations}/search`);
            this.locations = response.map(l => new LookupItem(l));
        }

        private async getDepartments() {
            const response: Array<ILookupItem> = await apiClient.get(`api/lookup/${LookupGroup.Departments}/search`);
            this.departments = response.map(d => new LookupItem(d));
        }
    }

