import ApiClient, { ISendRequestParameters } from "./ApiClient";

export interface IErrorReport {
    occurred: Date|null|undefined; // would be overwritten on server
    exceptionType: string|null|undefined;
    application: string|null|undefined; // would be overwritten on server
    location: string|null|undefined;
    fullText: string|null|undefined;
}

module ErrorReporter {

    // NB - The STACK TRACE does not have the column, line, method from the ORIGINAL source code
    //      but is from the MINIFIED code.  We'd need to use the source maps to translate but does not
    //      seem to happen automatically.
    //
    //      See \\DEV00\d$\Users\markh\Projects\Stuff\StackTraceNode for some experimental code where
    //      I'm starting to figure this out.
    //      (I don't think this will work client-side as it takes a long time
    //      to download and process the source maps...)

    // content seems to get truncated at server end if there are '<' or '>' (???)
    const formatFullText = (text: string|null|undefined) => text?.replaceAll("<", "â—¯")?.replaceAll(">", "â—¯") ?? "";

    function sendReport(report: IErrorReport) {
        if(process.env.NODE_ENV === "development") {
            console.warn("âš¡ Did not send error report - development mode: ", report);
            return;
        }

        // add the version
        report.fullText = `version    : ${process.env.VUE_APP_VERSION}
${report.fullText}`;

        const url = ApiClient.resolveUrl("/api/errorReport/send");
        fetch(url, {
            method: "POST",
            credentials: "same-origin",
            headers: new Headers([["Content-Type", "application/json"]]),
            body: JSON.stringify(report)
        }).catch((reason: any) => {
            console.error("ðŸ“„ Failed to send report: ", report);
            console.error("âš¡ Failed to send becuase: ", reason);
            if(!navigator.onLine) {
                console.warn("âš¡ NOT ONLINE!");
            }
        });
    }

    export function windowErrorHandler(message: string | Event, source?: string, line?: number, column?: number, error?: Error) {
        sendReport({
            occurred: new Date(),
            exceptionType: `Client Script: ${message ?? "(message?)"}`,
            application: process.env.VUE_APP_NAME,
            location: `windowErrorHandler - source=${source} | line=${line} | column=${column}`,
            fullText: formatFullText(error?.stack)
        });
    }

    export function vueErrorHandler(error: Error, vm: Vue, info: string) {
        const exceptionType = `VueError: ${error.name ?? "(name?)"} - ${error.message ?? "(message?)"}`;
        const line = (error as any).lineNumber ?? "(?)";
        const column = (error as any).columnNumber ?? "(?)";
        const fileName = (error as any).fileName ?? "(?)";
        const fullText = `message    : ${error?.message ?? "(?)"}
name       : ${error?.name ?? "(?)"}
info       : ${info}
stack      : ${error?.stack ?? "(no stack)"}`;
        sendReport({
            occurred: new Date(),
            exceptionType,
            application: process.env.VUE_APP_NAME,
            location: `vueErrorHandler - source:${fileName} | line:${line} | column:${column}`,
            fullText: formatFullText(fullText)
        });
    }

    // It's hard to imagine this report going through if isOnline is false but let's try...
    // (maybe we could batch failed sends and squirt them out later? - but tbh, it's offline, there's a chance we know what the problem is!)
    export function apiClientErrorHandler(reason: any, parameters: ISendRequestParameters, isOnline: boolean) {
        const isError = reason instanceof Error;
        const message = isError ? reason.message : typeof reason === "string" ? reason : null;

        // maybe we're sending a password in the error report?
        if (typeof parameters.postBody === "string" && parameters.postBody.indexOf('password":') > -1) parameters.postBody = "(possibly contains password - redacted!)";
        if (typeof parameters.postBody === "object") blankOutAnyPasswords(parameters.postBody);

        const fullText = `
was online : ${isOnline ? "yes" : "NO"}
parameters : ${JSON.stringify(parameters)}
error      : ${typeof reason === "object" ? JSON.stringify(reason) : String(reason)}
`;
        sendReport({
            occurred: new Date(),
            exceptionType: `API Client Error: ${message ?? "(no message)"}`,
            application: process.env.VUE_APP_NAME,
            location: "ApiClient",
            fullText: formatFullText(fullText)
        });
    }

    const blankOutAnyPasswords = (object: any) => {
        Object.keys(object).forEach((k: string) => {
            if (k === "password" && typeof object[k] === "string") {
                object[k] = "(redacted)";
            }
            // null has typeof object!
            else if (object[k] && typeof object[k] === "object") {
                blankOutAnyPasswords(object[k]);
            }
        });
    }

    // general dogsbody sender - can use in code
    export function send(error: any) {
        if(!error) {
            console.warn("âš ï¸ ErrorReporter.send() called but nothing to send!");
        }
        if(error instanceof Error) {
            sendReport({
                occurred: new Date(),
                exceptionType: "ClientScript",
                application: process.env.VUE_APP_NAME,
                location: null,
                fullText: formatFullText(error.stack)
            });
        }
        if(typeof error === "string") {
            sendReport({
                occurred: new Date(),
                exceptionType: "ClientScript",
                application: process.env.VUE_APP_NAME,
                location: null,
                fullText: formatFullText(error)
            });
        }
        if(typeof error === "object" && error.hasOwnProperty("occurred") && error.hasOwnProperty("exceptionType")) {
            error.fullText = formatFullText(error.fullText);
            sendReport(error);
        }
    }
}

export default ErrorReporter;