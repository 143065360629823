
    import Vue from "vue";
    import Component from "vue-class-component";
    import apiClient from "@/utilities/ApiClient";

    @Component({})
    export default class BlankLayout extends Vue {
        
        private get backgroundImage(): string {
            return apiClient.resolveUrl(`/api/file/background/${new Date().getTime()}`);
        }

    }
