import Vue from "vue";
import VueRouter, { RouteConfig, NavigationGuard, Route, RawLocation } from "vue-router";
import store from "@/store/store";
import dirtyness from "@/utilities/Dirtyness";

Vue.use(VueRouter as any); // eslint-disable-line @typescript-eslint/no-explicit-any

let intendedUrl: string | null = null;

// call this in the *component* code (not here in the router setup)
export function beforeRouteLeave(to: Route, from: Route,  next: (to?: string | void | false) => void) {
    if(dirtyness.isDirty && !confirm("Changes may not be saved.  Click cancel to stay on this page.")) {
        return next(false);
    }
    else {
        dirtyness.reset();
        return next();
    }
}

const beforeEnterGuard: NavigationGuard = async (to: Route, from: Route, next: (to?: string | void) => void) => {

    //console.log(`### nav guard... signed in=${store.getters.isSignedIn} intendedUrl=${intendedUrl}`);

    if (store.getters.isSignedIn) {
        if (intendedUrl) {
            const url = intendedUrl;
            intendedUrl = null;
            console.log("### nav guard - now logged in - redirect");
            return next(url); // goto stored url
        } else {
            console.log("### nav guard - carry on...");
            return next(); // all is fine
        }
    }
    
    try {
        // get user from session storage
        await store.dispatch("loadSignedInUser");
    } catch (e) {
        console.log("@@@ loadSignedInUser failed ");
        console.error(e);
    } 
    
    if (store.getters.isSignedIn) {
        console.log("### nav guard - got login ok");
        next();
    } else {
        intendedUrl = to.path; // store entry url before redirect
        console.log("### nav guard - redirect to signin");
        next("/signIn");
    }
};

//
// -- set up the routes
//

const routes: Array<RouteConfig> = [];

routes.push({ path: "/", name: "HomeSignIn", meta: { layout: "blank" }, component: () => import("../views/SignIn.vue") });
routes.push({ path: "/signin", name: "SignIn", meta: { layout: "blank" }, component: () => import("../views/SignIn.vue") });
routes.push({ path: "/Reset/:key", name: "ResetPassword", meta: { layout: "blank" }, component: () => import("../views/ResetPassword.vue") });
routes.push({ path: "/Activate/:key", name: "ActivateAccount", meta: { layout: "blank" }, component: () => import("../views/ResetPassword.vue") });
routes.push({ path: "/Error", name: "Error500", meta: { layout: "signed-out" }, component: () => import("../views/Error500.vue") });

routes.push({ path: "/Profile", name: "Profile", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => import("../views/Profile.vue") });
routes.push({ path: "/Assets", name: "Assets", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => import("../views/Assets.vue") });
routes.push({ path: "/Asset/:key", name: "Asset", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => import("../views/AssetDetail.vue") });
routes.push({ path: "/Issues", name: "Issues", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => import("../views/Issues.vue") });
routes.push({ path: "/MarkedForDeletion", name: "MarkedForDeletion", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => import("../views/MarkedForDeletion.vue") });
routes.push({ path: "/Categories", name: "Categories", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => import("../views/Categories.vue") });
routes.push({ path: "/Clients", name: "Clients", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => import("../views/Clients.vue") });
routes.push({ path: "/Departments", name: "Departments", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => import("../views/Lookups.vue") });
routes.push({ path: "/Locations", name: "Locations", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => import("../views/Lookups.vue") });
routes.push({ path: "/Users", name: "Users", meta: { layout: "signed-in" }, beforeEnter: beforeEnterGuard, component: () => import("../views/Users.vue") });

// This needs to be the **last** route
routes.push({ path: "*", meta: { layout: "signed-out" }, component: () => import("../views/Error404.vue") });

//
// -- create the router
//

const router = new VueRouter({
    mode: "history",
    linkActiveClass: "active",
    linkExactActiveClass: "active",
    base: process.env.BASE_URL,
    routes
});

export function goToIntendedUrl() {
    if (!intendedUrl) router.replace("/Assets"); // default in case not defined (?)
    else router.replace(intendedUrl as RawLocation);
}

export default router;
