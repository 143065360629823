// Same as TriState in Visual Basic
export enum TriState {
    UseDefault = -2, // 0xFFFFFFFE
    True = -1, // 0xFFFFFFFF
    False = 0
}

export enum UserRole {
    SuperAdmin = 1,
    Admin = 2,
    Basic = 3,
    NonEquator = 4
}

export enum UserStatus {
    RegistrationRequest = 1,
    ActivationEmailSent = 2,
    Active = 3,
    Deleted = 4
}

export enum LookupGroup {
    All = 0,
    Departments = 1,
    Locations = 2
}

export enum SignInView {
    SignIn = 1,
    FogottenPassword = 2,
    Register = 3
}

export enum AssetStatus {
    New = 1,
    Updated = 2,
    Active = 3,
    MarkedForDeletion = 4,
    Archived = 5
}

export enum AssetFileStatus {
    Active = 1,
    MarkedForDeletion = 2,
    Archived = 3
}

export enum ObjType {
    Asset = 1,
    File = 2
}

export enum HistoryType {
    None,
    Created,
    Updated,
    MarkedForDeletion,
    MarkForDeletionOverridden,
    Archived,
    Restored,
    IssueLogged,
    IssueDeleted,
    FileAdded,
    FileUpdated,
    FileDeleted,
    FileMarkedForDeletion,
    FileMarkForDeletionOverridden,
    FileIssueLogged,
    FileIssueDeleted,
    NoteAdded,
    NoteUpdated,
    NoteDeleted,
    FileArchived,
    FileRestored
}

export enum HistoryDialogType {
    AssetHistory = 1,
    UserDownloadHistory = 2,
    FileDownloadHistory = 3
}